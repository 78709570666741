
export const getCategorias = (state) => {
    return state.categorias
}

export const getBuscat = (state) => (search = '') => {
    if (search.length === 0) return state.categorias
    let categorias = []
    if (search.length > 0) {
        for (let i = 0; i < state.categorias.length; i++) {
            if (state.categorias[i].nombre.toLowerCase().includes(search.toLowerCase())) {
                console.log(' entrando a nombres')
                categorias.push(state.categorias[i])
            }
        }
        if (categorias.length > 0) {
            console.log(categorias)
            return categorias
        } else {
            return []
        }
    }
}


export const getBusId = (state) => (id) => {
    const categoria = state.categorias.find(categoria => categoria.id == id)
    if (!categoria) return
    return { ...categoria }
}



