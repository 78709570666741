import generalApi from '@/api/generalApi.js'
import sanitizeTitle from '@/modules/club/helpers/slug.js'

export const setClubs = async ({ commit }) => {
    const clubs = await generalApi.get('/v1/club/listado')
    console.log(clubs.data)
    commit('mutClubs', clubs.data)
}


export const selectClub = async ({ commit }, id) => {
    commit('selectClub', id)
}

export const updateClub = async ({ commit }, club) => {
    try {
        const { nombre } = club[0]
        const slug = club[1]
        const id = club[2]
        const dataToSave = { id, nombre, slug }
        const resp = await generalApi.put(`/v1/club/${id}`, dataToSave)
        return resp
    } catch (error) {
        return error.response.data
    }
}
export const createClub = async ({ commit }, club) => {
    try {
        const { nombre } = club[0]
        const slug = club[1]
        const dataToSave = { nombre, slug }
        const resp = await generalApi.post(`/v1/club/register`, dataToSave)
        return resp.data
    } catch (error) {
        return error.response.data
    }
}
export const deleteClub = async ({ commit }, id) => {
    const del = await generalApi.delete(`/v1/club/${id}`)
    if (del.data.error) {
        return del.data
    } else {
        commit('deleteClub', id)
        return id
    }
}

