export const getEventos = (state) => {
    return state.eventos
}

export const getEventosPendientes = (state) => {
    return state.eventospendientes
}

export const getAtletaSeleccionado = (state) => {
    return state.atletaseleccionado
}

export const getBusEvento = (state) => (search = '') => {
    if (search.length === 0) return state.eventos;
    let eventos = []
    if (search.length > 0) {
        for (let i = 0; i < state.eventos.length; i++) {
            if (state.eventos[i].nombre.toLowerCase().includes(search.toLowerCase())) {
                eventos.push(state.eventos[i])
            }
        }
        if (eventos.length > 0) {
            return eventos
        } else {
            return []
        }
    }
}
export const getBusId = (state) => (id) => {
    const evento = state.eventos.find(evento => evento.id == id)
    if (!evento) return
    return { ...evento }
}

