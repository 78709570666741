import store from '@/store'


const isAuthenticatedGuard = async (to, from, next) => {

    const { resp } = await store.dispatch('auth/checkAuthentication')
    console.log('respuesta', resp)
    if (resp) {
        next()
    } else {
        next({ name: 'login' })
    }
}

export default isAuthenticatedGuard