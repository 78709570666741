import generalApi from '@/api/generalApi.js'
import sanitizeTitle from '@/modules/categorias/helpers/slug.js'


export const setCategorias = async ({ commit }) => {
    const categorias = await generalApi.get('/v1/categoria/listado')
    console.log(categorias.data)
    commit('mutCategorias', categorias.data)
}


export const selectCategoria = async ({ commit }, id) => {
    commit('selectCategoria', id)
}

export const updateCategoria = async ({ commit }, categoria) => {


    try {
        const { id, nombre, descripcion, desde, hasta } = categoria[0]
        const slug = categoria[1]

        const dataToSave = { id, nombre, descripcion, desde, hasta, slug }

        const resp = await generalApi.put(`/v1/categoria/${id}`, dataToSave)
        return resp
    } catch (error) {
        return error.response.data
    }

}
export const createCategoria = async ({ commit }, categoria) => {
    try {
        // const { nombre, descripcion } = categoria[0]
        const { nombre, descripcion, desde, hasta } = categoria[0]
        const slug = categoria[1]

        // const dataToSave = { nombre, descripcion, slug }
        const dataToSave = { nombre, descripcion, desde, hasta, slug }

        const resp = await generalApi.post(`/v1/categoria/register`, dataToSave)

        return resp.data
        //return resp
    } catch (error) {
        return error.response.data
    }
}
export const deleteCategoria = async ({ commit }, id) => {
    const del = await generalApi.delete(`/v1/categoria/${id}`)
    if (del.data.error) {
        return del.data
    } else {
        commit('deleteCategoria', id)
        return id
    }
}

