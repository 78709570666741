import generalApi from '@/api/generalApi.js'
import registerApiMail from '@/api/registerApiMail.js'
//import sanitizeTitle from '@/modules/comercio/helpers/slug.js'

export const setComercios = async ({ commit }) => {
    const comercios = await generalApi.get('/v1/comercio/listado')
    console.log(comercios.data)
    commit('mutComercios', comercios.data)
}


export const selectComercio = async ({ commit }, id) => {
    commit('selectComercio', id)
}

export const updateComercio = async ({ commit }, comercio) => {
    try {
        const { nombre, documento, email, cedula_rif } = comercio[0]
        const slug = comercio[1]
        const id = comercio[2]
        const enviar = comercio[3]
        const dataToSave = { id, nombre, email, documento, cedula_rif, slug, enviar }
        const resp = await generalApi.put(`/v1/comercio/${id}`, dataToSave)
        if (resp.data && enviar == '1') {
            if (resp.data.comercio) {
                const { dato } = await registerApiMail.get(`/comercio?token=${resp.data.comercio.token}&enviar=si`)
            }
        }
        return resp
    } catch (error) {
        return error.response.data
    }
}
export const createComercio = async ({ commit }, comercio) => {
    try {
        const { nombre, email, documento, cedula_rif } = comercio[0]
        const slug = comercio[1]
        const dataToSave = { nombre, slug, email, documento, cedula_rif }
        const resp = await generalApi.post(`/v1/comercio/register`, dataToSave)
        if (resp.data) {
            if (resp.data.comercio) {
                const { dato } = await registerApiMail.get(`/comercio?token=${resp.data.comercio.token}&enviar=no`)
            }
        }
        return resp.data
    } catch (error) {
        return error.response.data
    }
}
export const deleteComercio = async ({ commit }, id) => {
    const del = await generalApi.delete(`/v1/comercio/${id}`)
    if (del.data.error) {
        return del.data
    } else {
        commit('deleteComercio', id)
        return id
    }
}

