export default {
    name: 'franelas',
    component: () => import(/* webpackChunkName: "Franelas" */ '@/layouts/General.vue'),
    children: [
        {
            path: '',
            name: 'home-franelas',
            component: () => import('@/modules/franelas/view/Home.vue'),
        },
        {
            path: 'add',
            name: 'add-franelas',
            component: () => import('@/modules/franelas/view/Add.vue'),
            props: (route) => {
                return {
                    id: 'new'
                }
            }
        },
        {
            path: ':id',
            name: 'edit-franelas',
            component: () => import('@/modules/franelas/view/Add.vue'),
            props: (route) => {
                return {
                    id: route.params.id
                }
            }
        }
    ]
}