import authApi from '@/api/authApi'

// export const myAction = async ({ commit }) => {


// }


export const createUser = async ({ commit }, user) => {

    const { name, email, password } = user

    try {

        const { data } = await authApi.post(':signUp', { email, password, returnSecureToken: true })
        const { api_token, refreshToken } = data

        await authApi.post(':update', { displayName: name, api_token, refreshToken })

        delete user.password
        commit('loginUser', { user, api_token, refreshToken })

        return { ok: true }

    } catch (error) {
        return { ok: false, message: error.response.data.error.message }
    }

}


export const signInUser = async ({ commit }, user) => {
    const { email, password } = user
    const emails = email
    try {
        const { data } = await authApi.post('/auth/login', { email: emails, password })
        const { name, email, api_token, message, resp } = data.data
        //user.name = name
        user = data
        commit('loginUser', { user, api_token })
        return { resp: true }
    } catch (error) {
        console.log(error)
        // return { resp: false, message: error.response.data.error.message }
    }

}
export const checkAuthentication = async ({ commit }) => {
    const api_token = localStorage.getItem('api_token')
    if (!api_token) {
        commit('logout')
        return { resp: false, message: 'No hay token' }
    }
    try {
        const { data } = await authApi.post('/auth/check', { api_token })
        const { name, email } = data
        const user = {
            name: name,
            email
        }
        commit('loginUser', { user, api_token })
        return { resp: data.resp }
    } catch (error) {
        commit('logout')
        return { resp: false, message: error.response }
    }
}