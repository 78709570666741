
export const mutConfigs = (state, configs) => {
    state.configs = configs
}
/*
export const updateConfig = (state, config) =>{
    const idx = state.configs.map( e => e.id ).indexOf( config.id )
    state.configs[idx] = config
}*/


export const selectConfig = (state, id) => {

}

export const deleteConfig = (state, id) => {
    state.configs = state.configs.filter(config => config.id !== id)
}

export const selectItemMenu = (state, item) => {
    state.activeMenu = item
}


export const setLoading = (state) => {
    state.isLoading = true
}
export const delLoading = (state) => {
    state.isLoading = false
}