
export const getComercios = (state) => {
    return state.comercios
}

export const getBusclu = (state) => (search = '') => {
    if (search.length === 0) return state.comercios
    let comercios = []
    if (search.length > 0) {
        for (let i = 0; i < state.comercios.length; i++) {
            if (state.comercios[i].nombre.toLowerCase().includes(search.toLowerCase())) {
                comercios.push(state.comercios[i])
            }
        }
        if (comercios.length > 0) {
            return comercios
        } else {
            return []
        }
    }
}


export const getBusId = (state) => (id) => {
    const comercio = state.comercios.find(comercio => comercio.id == id)
    if (!comercio) return
    return { ...comercio }
}



