import generalApi from '@/api/generalApi.js'

export const setConfigs = async ({ commit }) => {
    const configs = await generalApi.get('/v1/config')
    //console.log(configs.data)
    commit('mutConfigs', configs.data)
}


export const selectConfig = async ({ commit }, id) => {
    commit('selectConfig', id)
}

export const updateConfig = async ({ commit }, config) => {
    //console.log('este noooo')
    /*const resp = await generalApi.put(`/v1/config/1`, config)*/
}
export const createConfig = async ({ commit }, config) => {

    const { nombre, descripcion } = config[0]
    const slug = config[1]

    const dataToSave = { nombre, descripcion, slug }

    const resp = await generalApi.post(`/v1/config/register`, dataToSave)

    return resp.data.id
}
export const deleteConfig = async ({ commit }, id) => {
    await generalApi.delete(`/v1/config/${id}`)
    commit('deleteConfig', id)
    return id
}

export const selectItemMenu = ({ commit }, item) => {
    commit('selectItemMenu', item)
}


export const setLoading = ({ commit }) => {
    commit('setLoading')
}
export const delLoading = ({ commit }) => {
    commit('delLoading')
}


export const contrasenia = async ({ commit }, dat) => {
    const data = await generalApi.put(`/v1/config/1/cambiar`, dat)
    return data
}