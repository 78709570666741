
// export const myAction = ( state ) => {

// }

export const mutOrganizaciones = (state, organizaciones) => {
    state.organizaciones = organizaciones
}
/*
export const updateCategoria = (state, categoria) =>{
    const idx = state.categorias.map( e => e.id ).indexOf( categoria.id )
    state.categorias[idx] = categoria
}*/


export const selectCategoria = (state, id) => {

}

export const deleteOrganizacion = (state, id) => {
    state.organizaciones = state.organizaciones.filter(organizacion => organizacion.id !== id)
}