
export const mutFranelas = (state, franelas) => {
    state.franelas = franelas
}
/*
export const updateFranela = (state, franela) =>{
    const idx = state.franelas.map( e => e.id ).indexOf( franela.id )
    state.franelas[idx] = franela
}*/


export const selectFranela = (state, id) => {

}

export const deleteFranela = (state, id) => {
    state.franelas = state.franelas.filter(franela => franela.id !== id)
}