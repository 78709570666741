
export const mutComercios = (state, comercios) => {
    state.comercios = comercios
}
/*
export const updateComercio = (state, comercio) => {
    const idx = state.comercios.map(e => e.id).indexOf(comercio.id)
    state.comercios[idx] = comercio
}*/


export const selectComercio = (state, id) => {

}

export const deleteComercio = (state, id) => {
    state.comercios = state.comercios.filter(comercio => comercio.id !== id)
}