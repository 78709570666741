
// export const myAction = ( state ) => {

// }


export const loginUser = (state, { user, api_token }) => {

    if (api_token) {
        localStorage.setItem('api_token', api_token)
        state.api_token = api_token
    }
    state.user = user
    state.resp = user.resp
    state.status = 'authenticated'

}


export const logout = (state) => {
    state.user = null
    state.api_token = null
    state.status = 'not-authenticated'
    localStorage.removeItem('api_token')
}
