export const mutEventos = (state, eventos) => {
    state.eventos = eventos
}


export const selectCategoria = (state, id) => {

}

export const deleteEvento = (state, id) => {
    state.eventos = state.eventos.filter(evento => evento.id !== id)
}


export const selectAtleta = (state, atleta) => {
    state.atletaseleccionado = atleta
}

