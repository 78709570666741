
export const mutSliders = (state, sliders) => {
    state.sliders = sliders
}
/*
export const updateSlider = (state, slider) =>{
    const idx = state.sliders.map( e => e.id ).indexOf( slider.id )
    state.sliders[idx] = slider
}*/


export const selectSlider = (state, id) => {

}

export const deleteSlider = (state, id) => {
    state.sliders = state.sliders.filter(slider => slider.id !== id)
}