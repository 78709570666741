import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import VueClipboard from 'vue-clipboard2'

import 'sweetalert2/dist/sweetalert2.min.css';
import '@/assets/styles.css'
import './assets/js.js'
import 'boxicons';

VueClipboard.config.autoSetContainer = true // add this line

createApp(App)
    .use(router)
    .use(store)
    .use(VueClipboard)
    .mount('#app')
