import generalApi from '@/api/generalApi.js'

export const setSliders = async ({ commit }) => {
    const { data } = await generalApi.get('/v1/config/slider')
    console.log(data)
    commit('mutSliders', data)
}


export const selectSlider = async ({ commit }, id) => {
    commit('selectSlider', id)
}

export const updateSlider = async ({ commit }, slider) => {
    try {
        const resp = await generalApi.put(`/v1/config/slider/${slider[1]}`, slider[0])
        return resp.data
    } catch (error) {
        return error.response.data
    }
}
export const createSlider = async ({ commit }, slider) => {
    try {
        const resp = await generalApi.post(`/v1/config/slider/register`, slider)
        return resp.data
    } catch (error) {
        return error.response.data
    }
}
export const deleteSlider = async ({ commit }, id) => {
    const del = await generalApi.delete(`/v1/config/slider/${id}`)
    if (del.data.error) {
        return del.data
    } else {
        commit('deleteSlider', id)
        return id
    }
}

