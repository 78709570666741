<template>
  <span v-if="authStatus === 'authenticating'"></span>
  <router-view v-else />
</template>


<script>
import useAuth from './modules/auth/composables/useAuth'


export default {
  setup() {
    const { authStatus, checkAuthStatus } = useAuth();
    checkAuthStatus();
    return {
      authStatus
    };
  }
}
</script>
