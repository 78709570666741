export const getAtletas = (state) => {
    return state.atletas
}

export const getCategorias = (state) => {
    return state.categorias
}

export const getCategoriasLength = (state) => {
    return state.categorias.length
}

export const getClub = (state) => {
    return state.clubs
}

export const getEstados = (state) => {
    return state.estados
}

export const getBuscat = (state) => (search = '') => {
    if (search.length === 0) return state.atletas
    let atletas = []
    if (search.length > 0) {
        for (let i = 0; i < state.atletas.length; i++) {
            if (state.atletas[i].nombres.toLowerCase().includes(search.toLowerCase())) {
                atletas.push(state.atletas[i])
            } else if (search.toString().toLowerCase().includes('km')) {
                if (state.atletas[i].distancia.includes(search.toString().toLowerCase())) {
                    atletas.push(state.atletas[i])
                }
            } else if (!isNaN(search)) {
                console.log('entrando2')

                if (state.atletas[i].cedula.includes(search)) {
                    atletas.push(state.atletas[i])
                } else {
                    if (state.atletas[i].numero.includes(search)) {
                        atletas.push(state.atletas[i])
                    }
                }
            } else {
                /*console.log('entrando')
                if (state.atletas[i].filter(element => element.categoria_id == search)) {
                    atletas.push(state.atletas[i])
                }*/
            }
        }
        if (atletas.length > 0) {
            console.log(atletas)
            return atletas
        } else {
            return []
        }
    }
}

export const getBuscatInscripciones = (state) => (search = '') => {
    if (search.length === 0) return state.atletas
    let atletas = []
    if (search.length > 0) {
        for (let i = 0; i < state.atletas.length; i++) {
            if (state.atletas[i].nombres.toLowerCase().includes(search.toLowerCase())) {
                atletas.push(state.atletas[i])
            } else if (search.toString().toLowerCase().includes('k')) {
                if (state.atletas[i].distancia.includes(search.toString().toLowerCase())) {
                    atletas.push(state.atletas[i])
                }
            } else if (!isNaN(search)) {
                if (state.atletas[i].cedula_rif.includes(search)) {
                    atletas.push(state.atletas[i])
                } else {
                    if (state.atletas[i].nrocorredor.includes(search)) {
                        atletas.push(state.atletas[i])
                    }
                }
            } else {
                /*console.log('entrando')
                if (state.atletas[i].filter(element => element.categoria_id == search)) {
                    atletas.push(state.atletas[i])
                }*/
            }
        }
        if (atletas.length > 0) {
            console.log(atletas)
            return atletas
        } else {
            return []
        }
    }
}

export const getBusId = (state) => (id) => {
    const atleta = state.atletas.find(atleta => atleta.id == id)
    if (!atleta) return
    return { ...atleta }
}

export const getEvento = (state) => {
    if (state.evento) {
        return { ...state.evento }
    }
}

export const getBusAtleta = (state) => (id = '') => {
    const atleta = state.atletas.find(atleta => atleta.id == id)
    if (!atleta) return
    return { ...atleta }
}

export const getBusCategoria = (state) => (id = '') => {
    const categoria = state.categorias.find(categoria => categoria.id == id)
    if (!categoria) return
    return { ...categoria }
}

export const getAtletaSeleccionado = (state) => {
    return state.atletaseleccionado
}

export const getSelectCategoria = (state) => {
    return state.categoria
}

export const getPendientes = (state) => {
    return state.pendientes
}

export const getPago = (state) => {
    return state.pago
}

//atletas bloqueados
export const getBusBloqueados = (state) => (id = '') => {
    let atletas = []
    for (let i = 0; i < state.atletas.length; i++) {
        if (state.atletas[i].bloqueado === '1') {
            atletas.push(state.atletas[i])
        }
    }
    if (atletas.length > 0) {
        return atletas
    } else {
        return []
    }
}

//atletas inscritos
export const getBusInscritos = (state) => (id = '') => {
    let atletas = []
    for (let i = 0; i < state.atletas.length; i++) {
        if (state.atletas[i].validar_pago === '1' && state.atletas[i].bloqueado === '0') {
            atletas.push(state.atletas[i])
        }
    }
    if (atletas.length > 0) {
        return atletas
    } else {
        return []
    }
}

//atletas preinscritos
export const getBusPreinscritos = (state) => (id = '') => {
    let atletas = []
    for (let i = 0; i < state.atletas.length; i++) {
        if (state.atletas[i].validar_pago === '0' && state.atletas[i].bloqueado === '0') {
            atletas.push(state.atletas[i])
        }
    }
    if (atletas.length > 0) {
        return atletas
    } else {
        return []
    }
}

export const getSetMenu = (state) => {
    return state.setmenu
}