export default {
    name: 'usuarios',
    component: () => import(/* webpackChunkName: "General" */ '@/layouts/General.vue'),
    children: [
        {
            path: '',
            name: 'home-usuarios',
            component: () => import('@/modules/usuarios/view/Home.vue'),
        },
        {
            path: 'add',
            name: 'add-usuarios',
            component: () => import('@/modules/usuarios/view/Add.vue'),
            props: (route) => {
                return {
                    id: 'new'
                }
            }
        },
        {
            path: ':id',
            name: 'edit-usuarios',
            component: () => import('@/modules/usuarios/view/Add.vue'),
            props: (route) => {
                return {
                    id: route.params.id
                }
            }
        }
    ]
}