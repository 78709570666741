export default {
    name: 'atletas',
    component: () => import(/* webpackChunkName: "General" */ '@/layouts/General.vue'),
    children: [
        {
            path: '',
            name: 'home-atletas',
            redirect: { name: 'home-eventos' },
            //component: () => import('@/modules/atletas/view/Home.vue'),
        },
        {
            path: 'add',
            name: 'add-atletas',
            component: () => import('@/modules/atletas/view/Add.vue'),
            props: (route) => {
                return {
                    id: 'new'
                }
            }
        },
        {
            path: 'carrera/:id/:idcarrera?/:fecha?',
            name: 'edit-atletas',
            component: () => import('@/modules/atletas/view/Add.vue'),
            props: (route) => {
                return {
                    id: route.params.id,
                    idcarrera: route.params.idcarrera,
                    fecha: route.params.fecha,
                }
            }
        },

        {
            path: 'resultados/:evento',
            name: 'evento-atletas',
            component: () => import('@/modules/atletas/view/Listado.vue'),
            props: (route) => {
                return {
                    evento: route.params.evento,
                }
            }
        },
        {
            path: 'inscripciones/:evento',
            name: 'evento-atletas-inscritos',
            component: () => import('@/modules/atletas/view/ListadoInscritos.vue'),
            props: (route) => {
                return {
                    evento: route.params.evento,
                }
            }
        },
        /*{
            path: ':evento/categoria/inscripciones',
            name: 'evento-atletas-inscripciones',
            component: () => import('@/modules/atletas/view/Listado.vue'),
            props: (route) => {
                return {
                    evento: route.params.evento,
                    resultados: route.params.resultados

                }
            }
        },*/


    ]
}