import { createStore } from 'vuex'

import auth from '../modules/auth/store'
import atletas from '../modules/atletas/store'
import eventos from '../modules/eventos/store'
import categorias from '../modules/categorias/store'
import franelas from '../modules/franelas/store'
import organizacion from '../modules/organizacion/store'
import slider from '../modules/slider/store'
import config from '../modules/config/store'
import club from '../modules/club/store'
import comercio from '../modules/comercios/store'
import usuario from '../modules/usuarios/store'

const store = createStore({
    modules: {
        auth,
        atletas,
        eventos,
        categorias,
        franelas,
        organizacion,
        slider,
        config,
        club,
        comercio,
        usuario
    }
})

export default store