
export const getUsuarios = (state) => {
    return state.usuarios
}

export const getBususu = (state) => (search = '') => {
    if (search.length === 0) return state.usuarios
    let usuarios = []
    if (search.length > 0) {
        for (let i = 0; i < state.usuarios.length; i++) {
            if (state.usuarios[i].nombre.toLowerCase().includes(search.toLowerCase())) {
                usuarios.push(state.usuarios[i])
            }
        }
        if (usuarios.length > 0) {
            return usuarios
        } else {
            return []
        }
    }
}


export const getBusId = (state) => (id) => {
    const usuario = state.usuarios.find(usuario => usuario.id == id)
    if (!usuario) return
    return { ...usuario }
}



