
export const getConfigs = (state) => {
    return state.configs
}

export const getBuscat = (state) => (search = '') => {
    if (search.length === 0) return state.configs
    let configs = []
    if (search.length > 0) {
        for (let i = 0; i < state.configs.length; i++) {
            if (state.configs[i].nombre.toLowerCase().includes(search.toLowerCase())) {
                console.log(' entrando a nombres')
                configs.push(state.configs[i])
            }
        }
        if (configs.length > 0) {
            console.log(configs)
            return configs
        } else {
            return []
        }
    }
}

export const getBusId = (state) => (id) => {
    const config = state.configs.find(config => config.id == id)
    if (!config) return
    return { ...config }
}


export const getActiveMenu = (state) => {
    return state.activeMenu
}



