
// export const myGetter = ( state ) => {
//  return state
// }

export const getOrganizaciones = (state) => {
    return state.organizaciones
}

export const getBusOrganizacion = (state) => (search = '') => {
    if (search.length === 0) return state.organizaciones
    let organizaciones = []
    if (search.length > 0) {
        for (let i = 0; i < state.organizaciones.length; i++) {
            if (state.organizaciones[i].nombre.toLowerCase().includes(search.toLowerCase())) {
                organizaciones.push(state.organizaciones[i])
            }
        }
        if (organizaciones.length > 0) {
            return organizaciones
        } else {
            return []
        }
    }
}
export const getBusId = (state) => (id) => {
    const organizacion = state.organizaciones.find(organizacion => organizacion.id == id)
    console.log('getter')
    console.log('id: ', id)
    console.log('organizacion: ', organizacion)
    if (!organizacion) return
    return { ...organizacion }
}


