
export const getSliders = (state) => {
    return state.sliders
}

export const getBuscat = (state) => (search = '') => {
    if (search.length === 0) return state.sliders
    let sliders = []
    if (search.length > 0) {
        for (let i = 0; i < state.sliders.length; i++) {
            if (state.sliders[i].titulo.toLowerCase().includes(search.toLowerCase())) {
                console.log(' entrando a nombres')
                sliders.push(state.sliders[i])
            }
        }
        if (sliders.length > 0) {
            console.log(sliders)
            return sliders
        } else {
            return []
        }
    }
}


export const getBusId = (state) => (id) => {
    const slider = state.sliders.find(slider => slider.id == id)
    if (!slider) return
    return { ...slider }
}



