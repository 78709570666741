
import axios from 'axios'


const eventosApi = axios.create({
    baseURL: 'https://api.purocrono.com'
    // baseURL: 'https://api.purocrono.com',
})
eventosApi.interceptors.request.use((config) => {

    config.headers = {
        Authorization: `Bearer ${localStorage.getItem('api_token')}`,
        Accept: "application/json",
        "Content-Type": "application/json",
        responseType: "json",
    }
    return config
})
//  baseURL: 'https://apipurocrono.chdesarrolloweb .com'
// console.log( process.env.NODE_ENV ) // TEST durante testing, 

export default eventosApi


