
export const mutAtletas = (state, dato) => {
    state.atletas = dato[0]
    state.evento = dato[1]
    state.categorias = dato[2]
    state.estados = dato[3]
    state.clubs = dato[4]
    state.clubs = dato[4]
}
export const mutAtletas2 = (state, dato) => {
    state.atletas = dato[0]
    state.evento = dato[1]
    state.categorias = dato[2]
    state.estados = dato[3]
    state.pendientes = dato[4]
    state.pago = dato[5]
}
/*
export const updateAtleta = (state, atleta) =>{
    const idx = state.atletas.map( e => e.id ).indexOf( atleta.id )
    state.atletas[idx] = atleta
}*/


export const selectAtleta = (state, atleta) => {
    state.atletaseleccionado = atleta
}

export const selectCategoria = (state, categoria) => {
    state.categoria = categoria
}

export const deleteAtleta = (state, id) => {
    //state.atletas = state.atletas.filter(atleta => atleta.id !== id)
    const idx = state.atletas.map(e => e.id).indexOf(id)
    state.atletas[idx].bloqueado = '1'
}
export const restaurarAtleta = (state, id) => {
    const idx = state.atletas.map(e => e.id).indexOf(id)
    state.atletas[idx].bloqueado = '0'
}
export const confirmarPagoId = (state, id) => {
    const idx = state.atletas.map(e => e.id).indexOf(id)
    state.atletas[idx].validar_pago = '1'
}

export const selectedEvento = (state, evento) => {
    state.evento = evento
}

export const vaciar = (state) => {
    state.atletas = [];
}
export const vaciarPendientes = (state) => {
    state.pendientes = [];
}

export const vaciarInscritos = (state) => {
    state.atletasInscritos = [];
}

export const setCategorias = (state, data) => {
    console.log('state', data.categorias)
    state.categorias = data.categorias
    state.clubs = data.clubs
}


export const setMenu = (state, valor) => {
    state.setmenu = valor
    state.full = valor
}