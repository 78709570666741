import generalApi from '@/api/generalApi.js'
import sanitizeTitle from '@/modules/franelas/helpers/slug.js'


export const setFranelas = async ({ commit }) => {
    const franelas = await generalApi.get('/v1/franela/listado')
    console.log(franelas.data)
    commit('mutFranelas', franelas.data)
}


export const selectFranela = async ({ commit }, id) => {
    commit('selectFranela', id)
}

export const updateFranela = async ({ commit }, franela) => {


    try {
        const id = franela.id
        const talla = franela.talla
        const sexo = franela.sexo
        const carrera_id = franela.carrera_id

        const dataToSave = {id, talla, sexo, carrera_id }

        const resp = await generalApi.put(`/v1/franela/${id}`, dataToSave)
        return resp
    } catch (error) {
        return error.response.data
    }

}
export const createFranela = async ({ commit }, franela) => {
    try {
        const talla = franela.talla
        const sexo = franela.sexo
        const carrera_id = franela.carrera_id

        const dataToSave = { talla, sexo, carrera_id }

        const resp = await generalApi.post(`/v1/franela/register`, dataToSave)

        return resp.data
    } catch (error) {
        return error.response.data
    }
}
export const deleteFranela = async ({ commit }, id) => {
    const del = await generalApi.delete(`/v1/franela/${id}`)
    if (del.data.error) {
        return del.data
    } else {
        commit('deleteFranela', id)
        return id
    }
}

