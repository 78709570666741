
export const mutUsuarios = (state, usuarios) => {
    state.usuarios = usuarios
}
/*
export const updateUsuario = (state, usuario) =>{
    const idx = state.usuarios.map( e => e.id ).indexOf( usuario.id )
    state.usuarios[idx] = usuario
}*/


export const selectUsuario = (state, id) => {

}

export const deleteUsuario = (state, id) => {
    state.usuarios = state.usuarios.filter(usuario => usuario.id !== id)
}