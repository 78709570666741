
import axios from 'axios'

const registerApiMail = axios.create({
    baseURL: 'https://correo.purocrono.com'
})


export default registerApiMail


