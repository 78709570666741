
export const mutCategorias = (state, categorias) => {
    state.categorias = categorias
}
/*
export const updateCategoria = (state, categoria) =>{
    const idx = state.categorias.map( e => e.id ).indexOf( categoria.id )
    state.categorias[idx] = categoria
}*/


export const selectCategoria = (state, id) => {

}

export const deleteCategoria = (state, id) => {
    state.categorias = state.categorias.filter(categoria => categoria.id !== id)
}