export default () => ({
    atletas: [],
    evento: {},
    categoria: {},
    categorias: [],
    clubs: [],
    atletasInscritos: [],
    pendientes: [],
    pago: {},
    setmenu: true,
    full: true
})