import generalApi from '@/api/generalApi.js'
import registerApiMail from '@/api/registerApiMail.js'

import sanitizeTitle from '@/modules/atletas/helpers/slug.js'

export const setAtletas = async ({ commit }, evento) => {
    const atletas = await generalApi.get(`/v1/atleta/${evento}/categoria`)
    const a = atletas.data.atletas
    const e = atletas.data.evento
    const c = atletas.data.categorias
    const es = atletas.data.estados
    const cl = atletas.data.clubs
    commit('mutAtletas', [a, e, c, es, cl])
}

export const setAtletasInscripciones = async ({ commit }, evento) => {
    const atletas = await generalApi.get(`/v1/atleta/${evento}/inscripciones`)
    const a = atletas.data.atletas
    const e = atletas.data.evento
    const c = atletas.data.categorias
    const es = atletas.data.estados
    const pen = atletas.data.pendientes
    const p = atletas.data.pago

    //const cl = atletas.data.clubs
    commit('mutAtletas2', [a, e, c, es, pen, p])
}

export const selectAtleta = async ({ commit }, id) => {
    commit('selectAtleta', id)
}

export const selectCategoria = async ({ commit }, categoria) => {
    commit('selectCategoria', categoria)
}

export const atletaseleccionado = ({ commit }, atleta) => {
    commit('selectAtleta', atleta)
}

export const selectedEvento = ({ commit }, evento) => {
    commit('selectedEvento', evento)
}

export const selectedEventoInscripciones = ({ commit }, evento) => {
    commit('selectedEvento', evento)
}

export const seledEvento = ({ commit }, evento) => {
    commit('selectedEvento', evento)
}

export const updateAtleta = async ({ commit }, atleta) => {
    const resp = await generalApi.put(`/v1/atleta/${atleta[1]}`, atleta[0])
}

export const updatePago = async ({ commit }, dato) => {
    const resp = await generalApi.put(`/v1/pago/${dato.id}`, dato.pago)
    return resp.data
}


export const createAtleta = async ({ commit }, atleta) => {
    const resp = await generalApi.post(`/v1/atleta/register`, atleta)
    if (resp.data) {
        if (resp.data.token) {
            const { dato } = await registerApiMail.get(`/registro?token=${resp.data.token}`)
        }
    }
    return resp.data
}
export const deleteAtleta = async ({ commit }, id) => {
    const resp = await generalApi.delete(`/v1/atleta/${id}`)
    commit('deleteAtleta', id)
    return resp.data
}

export const confirmarIncorporarId = async ({ commit }, dato) => {
    const resp = await generalApi.get(`/v1/atleta/${dato.id}/restore`)
    commit('restaurarAtleta', dato.id)
    return resp.data
}

export const confirmarPagoId = async ({ commit }, id) => {
    const data = await generalApi.post(`/v1/atleta/${id}/confirmar-pago`)
    commit('confirmarPagoId', id)
    return data
}

export const AsignarDorsal = async ({ commit }, dato) => {
    const data = await generalApi.post(`/v1/atleta/${dato.id}/asignar-dorsal`, dato)
    if (data.data) {
        if (data.data.token) {
            const { dato } = await registerApiMail.get(`/dorsal?token=${data.data.token}`)
        }
    }
    return data
}
/*
export const confirmarPagoId = async ({ commit }, dorsal, id) => {
    await generalApi.post(`/v1/atleta/${id}/confirmar-pago`)
    commit('confirmarPagoId', id)
    return id
}*/


export const vaciar = async ({ commit }, id) => {
    await generalApi.delete(`/v1/atleta/vaciar/${id}`)
    commit('vaciar')
    return id
}
export const vaciarpendientes = async ({ commit }, id) => {
    const data = await generalApi.delete(`/v1/atleta/vaciarpendientes/${id}`)
    commit('vaciarPendientes')
    return data.data
}
export const vaciarInscritos = async ({ commit }, id) => {
    const data = await generalApi.delete(`/v1/atleta/vaciar-inscritos/${id}`)
    commit('vaciarInscritos')
    return data.data
}

export const setCats = ({ commit }, data) => {
    commit('setCategorias', data)
}


export const exportar = async ({ commit }, valor) => {
    const hast = localStorage.getItem('api_token')
    if (hast) {
        const resp = await generalApi.get(`/v1/export/${valor.id}?token=${hast}&tipo=${valor.tipo}`)
        return resp.data
    } else {
        localStorage.removeItem('hast_token')
    }
}


export const setMenu = async ({ commit }, valor) => {
    commit('setMenu', valor)
}
