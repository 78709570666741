import generalApi from "@/api/generalApi.js";

export const actEventos = async ({ commit }) => {
    const eventos = await generalApi.get('/v1/evento/listado')
    commit('mutEventos', eventos.data);
    return
}

export const actEventosActivos = async ({ commit }) => {
    const eventos = await generalApi.get('/consulta/carreras')
    commit('mutEventos', eventos.data.carreras);
    return
}

export const setEventos = async ({ commit }) => {
    const eventos = await generalApi.get('/v1/evento/listado')
    commit('mutEventos', eventos.data)
}

export const selectEvento = async ({ commit }, id) => {
    commit('selectEvento', id)
}

export const updateEvento = async ({ commit }, evento) => {
    console.log('action')
    console.log(evento)
    const resp = await generalApi.put(`/v1/evento/${evento[1]}`, evento[0])
}
export const createEvento = async ({ commit }, evento) => {

    const resp = await generalApi.post(`/v1/evento/register`, evento)
    commit('selectEvento', resp.data.id)
    return resp.data

}
export const deleteEvento = async ({ commit }, id) => {
    await generalApi.delete(`/v1/evento/${id}`)
    commit('deleteEvento', id)
    return id
}
export const atletaseleccionado = ({ commit }, atleta) => {
    commit('selectAtleta', atleta)
}


