export default {
    name: 'config',
    component: () => import(/* webpackChunkName: "General" */ '@/layouts/General.vue'),
    children: [
        /*{
            path: '',
            name: 'home-config',
            component: () => import('@/modules/config/view/Home.vue'),
        },
        {
            path: 'add',
            name: 'add-config',
            component: () => import('@/modules/config/view/Add.vue'),
            props: (route) => {
                return {
                    id: 'new'
                }
            }
        },*/
        {
            path: '',
            name: 'home-config',
            component: () => import('@/modules/config/view/Add.vue'),
        }
    ]
}