import generalApi from '@/api/generalApi.js'
import sanitizeTitle from '@/modules/organizacion/helpers/slug.js'

export const setOrganizaciones = async ({ commit }) => {
    const organizaciones = await generalApi.get('/v1/organizacion/listado')
    commit('mutOrganizaciones', organizaciones.data)
}

export const selectOrganizacion = async ({ commit }, id) => {
    commit('selectOrganizacion', id)
}

export const updateOrganizacion = async ({ commit }, organizacion) => {

    try {
        const resp = await generalApi.put(`/v1/organizacion/${organizacion[1]}`, organizacion[0])
        return resp.data
    } catch (error) {
        return error.response.data
    }
}
export const createOrganizacion = async ({ commit }, organizacion) => {
    try {
        const resp = await generalApi.post(`/v1/organizacion/register`, organizacion)
        return resp.data
    } catch (error) {
        return error.response.data
    }



}
export const deleteOrganizacion = async ({ commit }, id) => {
    const del = await generalApi.delete(`/v1/organizacion/${id}`)
    if (del.data.error) {
        return del.data
    } else {
        commit('deleteOrganizacion', id)
        return id
    }
}



