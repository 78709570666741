export default {
    name: 'slider',
    component: () => import(/* webpackChunkName: "General" */ '@/layouts/General.vue'),
    children: [
        {
            path: '',
            name: 'home-slider',
            component: () => import('@/modules/slider/view/Home.vue'),
        },
        // {
        //     path: 'add',
        //     name: 'add-slider',
        //     component: () => import('@/modules/slider/view/Add.vue'),
        //     props: (route) => {
        //         return {
        //             id: 'new'
        //         }
        //     }
        // },
        {
            path: ':id',
            name: 'edit-slider',
            component: () => import('@/modules/slider/view/Add.vue'),
            props: (route) => {
                return {
                    id: route.params.id
                }
            }
        }
    ]
}