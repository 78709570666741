import generalApi from '@/api/generalApi.js'
import registerApiMail from '@/api/registerApiMail.js'
//import sanitizeTitle from '@/modules/usuario/helpers/slug.js'

export const setUsuarios = async ({ commit }) => {
    const usuarios = await generalApi.get('/v1/usuario/listado')
    console.log(usuarios.data)
    commit('mutUsuarios', usuarios.data)
}


export const selectUsuario = async ({ commit }, id) => {
    commit('selectUsuario', id)
}

export const updateUsuario = async ({ commit }, usuario) => {
    try {
        const { nombres, email, telefono } = usuario[0]
        const carrera_id = usuario[1]
        const id = usuario[2]
        const enviar = usuario[3]
        const dataToSave = { id, nombres, email, telefono, carrera_id, enviar }
        const resp = await generalApi.put(`/v1/usuario/${id}`, dataToSave)
        if (resp.data && enviar == '1') {
            if (resp.data.usuario) {
                const { dato } = await registerApiMail.get(`/usuario?token=${resp.data.usuario.hast_token}&enviar=si`)
            }
        }
        return resp
    } catch (error) {
        return error.response.data
    }
}
export const createUsuario = async ({ commit }, usuario) => {
    try {
        const { nombres, email, telefono } = usuario[0]
        const carrera_id = usuario[1]
        const dataToSave = { nombres, email, telefono, carrera_id }
        const resp = await generalApi.post(`/v1/usuario/register`, dataToSave)
        console.log(resp)
        if (resp.data) {
            if (resp.data.usuario) {
                const { dato } = await registerApiMail.get(`/usuario?token=${resp.data.usuario.hast_token}&enviar=no`)
            }
        }
        return resp.data
    } catch (error) {
        return error.response.data
    }
}
export const deleteUsuario = async ({ commit }, id) => {
    const del = await generalApi.delete(`/v1/usuario/${id}`)
    if (del.data.error) {
        return del.data
    } else {
        commit('deleteUsuario', id)
        return id
    }
}


export const bloquearUsuario = async ({ commit }, id) => {
    const resp = await generalApi.get(`/v1/usuario/${id}/bloquear`)
    if (resp.data) {
        console.log('bloquear', resp.data)
        commit('mutUsuarios', resp.data.usuarios)
    }
    return resp.data
}

export const desbloquearUsuario = async ({ commit }, id) => {
    const resp = await generalApi.get(`/v1/usuario/${id}/desbloquear`)
    if (resp.data) {
        if (resp.data.usuario) {
            console.log('desbloquear', resp.data)
            commit('mutUsuarios', resp.data.usuarios)
            const { dato } = await registerApiMail.get(`/usuario?token=${resp.data.usuario.hast_token}&enviar=no`)
        }
    }
    return resp.data
}

