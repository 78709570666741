import { createRouter, createWebHistory, createWebHashHistory } from 'vue-router'
import Home from '../App.vue'

import isAuthenticatedGuard from '../modules/auth/router/auth-guard'

import authRouter from '../modules/auth/router'
import eventosRouter from '../modules/eventos/router'
import atletasRouter from '../modules/atletas/router'
import categoriasRouter from '../modules/categorias/router'
import franelasRouter from '../modules/franelas/router'
import clubRouter from '../modules/club/router'
import organizacionRouter from '../modules/organizacion/router'
import configRouter from '../modules/config/router'
import sliderRouter from '../modules/slider/router'
import comerciosRouter from '../modules/comercios/router'
import usuariosRouter from '../modules/usuarios/router'

const routes = [
  {
    path: '/',
    name: 'Home',
    redirect: '/eventos'
  },
  {
    path: '/auth',
    ...authRouter,
  },
  {
    path: '/eventos',
    beforeEnter: [isAuthenticatedGuard],
    ...eventosRouter
  },
  {
    path: '/atletas',
    beforeEnter: [isAuthenticatedGuard],
    ...atletasRouter
  },
  {
    path: '/categorias',
    beforeEnter: [isAuthenticatedGuard],
    ...categoriasRouter
  },
  {
    path: '/franelas',
    beforeEnter: [isAuthenticatedGuard],
    ...franelasRouter
  },
  {
    path: '/clubs',
    beforeEnter: [isAuthenticatedGuard],
    ...clubRouter
  },
  {
    path: '/organizacion',
    beforeEnter: [isAuthenticatedGuard],
    ...organizacionRouter
  },
  {
    path: '/slider',
    beforeEnter: [isAuthenticatedGuard],
    ...sliderRouter
  },
  {
    path: '/config',
    beforeEnter: [isAuthenticatedGuard],
    ...configRouter
  },
  {
    path: '/comercios',
    beforeEnter: [isAuthenticatedGuard],
    ...comerciosRouter
  },
  {
    path: '/usuarios',
    beforeEnter: [isAuthenticatedGuard],
    ...usuariosRouter
  }

  // {
  //   path: '/daybook',
  //   beforeEnter: [isAuthenticatedGuard],
  //   ...daybookRouter
  // },

  /*{
    path: '/resultados',
    name: 'resultados',
    component: () => import('../modules/web/view/Resultados.vue')
  },
  {
    path: '/eventos',
    name: 'eventos',
    component: () => import('../modules/web/view/Eventos.vue'),
  },
  {
    path: '/eventos/:slug',
    name: 'slug-resultados',
    component: () => import('../modules/web/view/ResultadosSlug.vue'),
    props: (route) => {
      return {
        slug: route.params.slug
      }
    }
  },
  {
    path: '/eventos/:slug/:categoria',
    name: 'slug-resultados-categorias',
    component: () => import('../modules/web/view/ResultadosSlug.vue'),
    props: (route) => {
      return {
        slug: route.params.slug,
        categoria: route.params.categoria
      }
    }
  },
  {
    path: '/about',
    name: 'About',
    component: () => import(/ * webpackChunkName: "about" * / '../views/About.vue')
  },
  {
    path: '/politicas-de-privacidad',
    name: 'politicas',
    component: () => import('../modules/web/view/Politicas.vue')
  },
  {
    path: '/terminos-y-condiciones',
    name: 'terminos',
    component: () => import('../modules/web/view/TerminosCondiciones.vue')
  },*/

  //,
  //{ path: "/:pathMatch(.*)*", component: () => import('@/modules/web/view/404Page'), name: 'page404' }

]

const router = createRouter({
  //history: createWebHashHistory(),
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      // This ensures that if hash is provided to router.push it works as expected.
      //  & since we have used "behavior: 'smooth'" the browser will slowly come to this hash position.
      return {
        el: to.hash,
        behavior: 'smooth',
      }
    }
  }
})

export default router
