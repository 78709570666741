
export const mutClubs = (state, clubs) => {
    state.clubs = clubs
}
/*
export const updateClub = (state, club) =>{
    const idx = state.clubs.map( e => e.id ).indexOf( club.id )
    state.clubs[idx] = club
}*/


export const selectClub = (state, id) => {

}

export const deleteClub = (state, id) => {
    state.clubs = state.clubs.filter(club => club.id !== id)
}