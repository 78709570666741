
export const getClubs = (state) => {
    return state.clubs
}

export const getBusclu = (state) => (search = '') => {
    if (search.length === 0) return state.clubs
    let clubs = []
    if (search.length > 0) {
        for (let i = 0; i < state.clubs.length; i++) {
            if (state.clubs[i].nombre.toLowerCase().includes(search.toLowerCase())) {
                clubs.push(state.clubs[i])
            }
        }
        if (clubs.length > 0) {
            return clubs
        } else {
            return []
        }
    }
}


export const getBusId = (state) => (id) => {
    const club = state.clubs.find(club => club.id == id)
    if (!club) return
    return { ...club }
}



