export default () => ({
    isLoading: true,
    isLoadingPend: true,
    eventos: [],
    eventoscategorias: [],
    eventospendientes: [],
    selectedEvento: {},
    configPage: [],
    search: '',
    page404: false,
    atletaseleccionado: {}
})