
export const getFranelas = (state) => {
    return state.franelas
}

export const getBusfra = (state) => (search = '') => {
    if (search.length === 0) return state.franelas
    let franelas = []
    if (search.length > 0) {
        for (let i = 0; i < state.franelas.length; i++) {
            if (state.franelas[i].talla.toLowerCase().includes(search.toLowerCase())) {
                console.log(' entrando a nombres')
                franelas.push(state.franelas[i])
            }
        }
        if (franelas.length > 0) {
            console.log(franelas)
            return franelas
        } else {
            return []
        }
    }
}


export const getBusId = (state) => (id) => {
    const franela = state.franelas.find(franela => franela.id == id)
    if (!franela) return
    return { ...franela }
}



